import UyapTakipAc from "../../../takip_ac/UyapTakipAc";
import MtsTakipAc from "../../../takip_ac/MtsTakipAc";

export class TakipAcTaskDataInterface extends UyapTakipAc {

}

export class MtsTakipAcTaskDataInterface extends MtsTakipAc {

}

