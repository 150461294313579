
import {Component, Prop, Vue} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import app from "@/main";

@Component({
  components: {FormWrapper}
})
export default class TakipTalebiGosterForm extends Vue {
  @Prop() dosyaId!: string;

  // xml olarak gönder
  url = "https://udf-preview.zirve-bilgisayar.com/?xml="
  isReady = false;

  takipTalebi!: BlobPart;

  // mounted() {
  //   this.getTakipTalebi();
  // }
  //
  // async getTakipTalebi() {
  //   let takipTalebiResult = await app.$uyap.TakipTalebiIndir(this.dosyaId).run({
  //     download: true,
  //     filename: "takip_talebi.udf"
  //   });
  //   this.takipTalebi = takipTalebiResult.file;
  //   // xmle dönüştür
  // }

  onDownload() {
    let fileURL = window.URL.createObjectURL(new Blob([this.takipTalebi]));
    let fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', 'takip-talebi-' + this.dosyaId);
    document.body.appendChild(fileLink);
    fileLink.click();
  }

  onClose() {
    this.$emit('onClose');
  }
}
